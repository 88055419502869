import styled from 'styled-components';

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;

  @media only screen and (max-width: 480px) {
    width: unset;
  }
`;
