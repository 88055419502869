import React, { createRef, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';

import { getWebpEnabled, setWebpEnabled } from '../../client-data/web-storage';
import { setDocBodyBgColor } from '../Utils/SetDocBodyBgColor';
import { controlChatbotVisibility } from '../Utils/uiControl';

import GoogleAnalytics from '../Utils/GoogleAnalytics';
import ScrollToTop from '../Utils/ScrollToTop';
import DefaultHelmet from '../Helmet/DefaultHelmet';
import Routes from '../Routes';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faTimes,
  faSignInAlt,
  faLongArrowAltRight,
  faLaptopCode,
  faMobileAlt,
  faServer,
  faStore,
  faSearchLocation,
  faPencilRuler,
  faStar as faStarSolid,
  faChevronRight,
  faComments,
  faArrowAltCircleDown,
  faHandPointer,
  faSpinner,
  faCheckCircle,
  faSurprise,
  faQuestionCircle,
  faVial,
  faGlasses,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import {
  faEnvelope,
  faStar as faStarOutline,
} from '@fortawesome/free-regular-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import styles from './App.module.css';
import theme from '../../theme/theme';

library.add(
  faBars,
  faTimes,
  faSignInAlt,
  faLongArrowAltRight,
  faEnvelope,
  faTwitter,
  faLaptopCode,
  faMobileAlt,
  faServer,
  faStore,
  faSearchLocation,
  faPencilRuler,
  faStarSolid,
  faStarOutline,
  faChevronRight,
  faComments,
  faArrowAltCircleDown,
  faHandPointer,
  faSpinner,
  faCheckCircle,
  faSurprise,
  faQuestionCircle,
  faVial,
  faGlasses,
  faPowerOff
);

const appRef = createRef();

const StyledMainContainer = styled.main`
  // transition must be slightly longer than header transition,
  // due to an inner shadow on the blog iframe.
  transition: margin 0.225s ease-in-out;
  margin-top: ${({ isScrolled, isA11yMode, theme: { heights } }) =>
    isScrolled || isA11yMode
      ? heights.stickyHeaderDesktopSmall
      : heights.stickyHeaderDesktop};

  @media only screen and (max-width: 480px) {
    margin-top: ${({ isScrolled, isA11yMode, theme: { heights } }) =>
      isScrolled || isA11yMode
        ? heights.stickyHeaderMobileSmall
        : heights.stickyHeaderMobile};
  }
`;

const App = () => {
  const { Modernizr } = window;
  const [webp, setWebp] = useState(getWebpEnabled() || true);
  const [pathname, setPathname] = useState(window.location.pathname);
  const [appIsScrollable, setAppIsScrollable] = useState(true);
  const [isA11yMode, setIsA11yMode] = useState(false);

  const [isScrolled, setIsScrolled] = useState(
    document.documentElement.scrollTop > 3
  );

  const [chatbotNode, setChatbotNode] = useState(
    document.body.querySelector('div#continually-container')
  );

  useEffect(
    () =>
      Modernizr.on('webp', (result) => {
        if (result !== webp) {
          setWebp(result);
          setWebpEnabled(result);
        }
      }),
    [] // eslint-disable-line
  );

  useEffect(() => {
    if (isA11yMode) setIsA11yMode(false);

    if (chatbotNode) {
      controlChatbotVisibility(chatbotNode, pathname);
    } else {
      setChatbotNode(document.body.querySelector('div#continually-container'));
    }

    setDocBodyBgColor();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chatbotNode) controlChatbotVisibility(chatbotNode, pathname);
  }, [chatbotNode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={theme}>
      <DefaultHelmet />
      <Router>
        <GoogleAnalytics />
        <ScrollToTop />
        <div
          ref={appRef}
          className={`${styles.app} ${appIsScrollable ? '' : styles.noScroll}`}
        >
          {pathname === '/' && (
            <div
              className={`${styles.staticBG} ${
                webp ? styles.webp : styles.noWebp
              }`}
            ></div>
          )}
          <Header
            isA11yMode={isA11yMode}
            isScrolled={isScrolled}
            setIsScrolled={setIsScrolled}
            setPathname={setPathname}
          />
          <StyledMainContainer isScrolled={isScrolled} isA11yMode={isA11yMode}>
            <Routes
              isA11yMode={isA11yMode}
              setIsA11yMode={setIsA11yMode}
              setAppIsScrollable={setAppIsScrollable}
              isScrolled={isScrolled}
              pathname={pathname}
            />
          </StyledMainContainer>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
