import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router';

import Home from './Main/Pages/Home/Home';
import FullPageLoader from './Shared/FullPageLoader';

const Portfolio = lazy(() => import('./Main/Pages/Portfolio/Portfolio'));
// TODO: Uncomment/comment next two lines to activate WebQuoteGen v2
// const WebQuoteGen = lazy(() => import('./Main/Pages/CustomQuoteGenerators/WebQuoteGenV2/WebQuoteGen'));
const WebQuoteGen = lazy(() =>
  import('./Main/Pages/CustomQuoteGenerators/WebQuoteGen')
);
const SubmitTicket = lazy(() => import('./Main/Pages/Support/SubmitTicket'));
const SignDocument = lazy(() =>
  import('./Main/Pages/SignDocument/SignDocument')
);
const SuccessMessage = lazy(() =>
  import('./Main/Pages/SuccessMessage/SuccessMessage')
);
const Legal = lazy(() => import('./Main/Pages/Privacy/Legal'));
const YouveGotMail = lazy(() =>
  import('./Main/Pages/YouveGotMail/YouveGotMail')
);
const ProjectStatus = lazy(() =>
  import('./Main/Pages/ProjectStatus/ProjectStatus')
);
const NotFound = lazy(() => import('./Main/Pages/NotFound/NotFound'));
const Blog = lazy(() => import('./Main/Pages/Blog/Blog'));
const TestPage = lazy(() => import('./Main/Pages/TestPage/TestPage'));

const Routes = ({
  isScrolled,
  pathname,
  isSitemap = false,
  setWebQuoteGenProgress,
  setAppIsScrollable,
  isA11yMode,
  setIsA11yMode,
}) => {
  return (
    <Suspense
      fallback={
        isSitemap ? null : <FullPageLoader offsetForHeader={pathname !== '/'} />
      }
    >
      <Switch>
        <Route exact path="/">
          {isSitemap ? null : <Home />}
        </Route>
        <Route path="/custom-website-quote-generator">
          {isSitemap ? null : (
            <WebQuoteGen setWebQuoteGenProgress={setWebQuoteGenProgress} />
          )}
        </Route>
        <Route path="/portfolio">{isSitemap ? null : <Portfolio />}</Route>
        {/* /privacy is required for legacy reasons */}
        <Route path="/privacy">
          {isSitemap ? null : <Legal documentType="privacy-policy" />}
        </Route>
        <Route path="/legal/:docTypeParam">
          {isSitemap ? null : <Legal />}
        </Route>
        <Route path="/support">{isSitemap ? null : <SubmitTicket />}</Route>
        <Route path="/sign">{isSitemap ? null : <SignDocument />}</Route>
        <Route path="/document-signature-success">
          {isSitemap ? null : <SuccessMessage />}
        </Route>
        <Route path="/submission-received">
          {isSitemap ? null : <YouveGotMail />}
        </Route>
        <Route path="/project">{isSitemap ? null : <ProjectStatus />}</Route>
        {/* Cannot use path={['/blog/:initBlogPath', '/blog']} due to incompatibility with react-router-sitemap */}
        <Route path="/blog/:initBlogPath">
          {isSitemap ? null : (
            <Blog
              isScrolled={isScrolled}
              setAppIsScrollable={setAppIsScrollable}
              isA11yMode={isA11yMode}
              setIsA11yMode={setIsA11yMode}
            />
          )}
        </Route>
        <Route path="/blog">
          {isSitemap ? null : (
            <Blog
              isScrolled={isScrolled}
              setAppIsScrollable={setAppIsScrollable}
              isA11yMode={isA11yMode}
              setIsA11yMode={setIsA11yMode}
            />
          )}
        </Route>
        <Route path="/test-page">{isSitemap ? null : <TestPage />}</Route>
        <Route>{isSitemap ? null : <NotFound />}</Route>
      </Switch>
    </Suspense>
  );
};

const sitemapRoutes = Routes({ isSitemap: true });

export { Routes as default, sitemapRoutes };
