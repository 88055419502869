import React, { createRef } from 'react';
import styles from './Hamburger.module.css';

const hamButton = createRef();

const Hamburger = ({ className, ariaLabel, isOpen, setIsOpen }) => {
  const handleClick = () => {
    !isOpen ? setIsOpen(true) : setIsOpen(false);
  };

  return (
    <button
      ref={hamButton}
      className={`${className} ${styles.hamburger} ${
        isOpen ? styles.close : styles.open
      }`}
      aria-label={ariaLabel}
      onClick={() => handleClick()}
    >
      <span className={styles.topSlice}></span>
      <span className={styles.middleSlice}></span>
      <span className={styles.bottomSlice}></span>
    </button>
  );
};

export default Hamburger;
