import styled from 'styled-components';

export const ColumnWrapper = styled.div`
  max-width: ${({ styledWidth }) => (styledWidth ? styledWidth : '100%')};
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 auto;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    width: unset;
  }
`;
