import React from 'react';
import LazyLoad from 'react-lazyload';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useLocation, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { isMobile } from 'react-device-detect';

import { MainRow } from '../Styled/Rows';
import { Row } from '../Styled/Rows';
import { Column } from '../Styled/Columns';
import { ColumnWrapper } from '../Styled/Wrappers';
import { IconButton } from '../Styled/Buttons';

import logoSquare from '../../assets/images/global/Red+Robin+Creative+Logo+v8B2-2+2019+-+WHITE+ON+RED-ccf824b6.png';
import { ReactComponent as Shape1 } from '../../assets/images/homepage/RRC-Shape2-BG.svg';

import styles from './Footer.module.css';

const Footer = () => {
  const { pathname } = useLocation();
  const lazyLoadOffsetHeight = 200;

  const getLazyLoadPlaceholderHeight = () => {
    const mobileHeight = 144;
    const desktopHeight = 160;

    return isMobile ? mobileHeight : desktopHeight;
  };

  return (
    <>
      {pathname.includes('blog') ? null : (
        <>
          <Shape1 className={styles.footerShape1} />
          <MainRow className={styles.footer}>
            <ColumnWrapper className={styles.wrapper}>
              <Column className={styles.col1}>
                <HashLink to="/#home">
                  <LazyLoad
                    height={getLazyLoadPlaceholderHeight()}
                    offset={lazyLoadOffsetHeight}
                    once={true}
                  >
                    <img
                      className={styles.footerLogo}
                      src={logoSquare}
                      alt="The Red Robin Creative Logo (Square Variant)"
                      title="Take me back to the homepage!"
                    />
                  </LazyLoad>
                </HashLink>
              </Column>
              <Column className={styles.col2}>
                <div>
                  <div
                    className={styles.address}
                    itemScope
                    itemType="https://schema.org/PostalAddress"
                  >
                    <h5 itemProp="name">Red Robin Creative</h5>
                    <span>
                      Proudly Creating in the{' '}
                      <span
                        style={{ display: 'inline' }}
                        itemProp="addressLocality"
                      >
                        UK
                      </span>{' '}
                      🇬🇧✨
                    </span>
                  </div>
                  <hr className={styles.spacer} />
                  <nav className={styles.supportLegal}>
                    <h5>Support &amp; Legal</h5>
                    <Link to="/legal/terms-and-conditions">
                      Terms &amp; Conditions
                    </Link>
                    <Link to="/legal/privacy-policy">Privacy Policy</Link>
                    <Link to="/legal/cookie-policy">Cookie Policy</Link>
                    <a
                      href="https://support.multiscreensite.com/hc/en-us"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Help Center
                    </a>
                  </nav>
                </div>
              </Column>
              <Column className={styles.col3}>
                <div className={styles.contact}>
                  <h5>Sales and Enquiries</h5>
                  <span>Email: hello@redrobincreative.com</span>
                  <span>Telephone: +44 1282 216095</span>
                  <hr className={styles.spacer} />
                  <h5>Technical Support</h5>
                  <span>Email: support@redrobincreative.com</span>
                  <span>Telephone: +44 333 880 1305</span>
                </div>
              </Column>
              <Column className={styles.col4}>
                <IconButton
                  onClick={() =>
                    window.location.assign(
                      'https://editor.redrobincreative.com/login'
                    )
                  }
                >
                  <Icon icon="sign-in-alt" />
                  Login
                </IconButton>
                <IconButton className="continually">
                  <Icon icon="comments" />
                  Chat
                </IconButton>
              </Column>
            </ColumnWrapper>
          </MainRow>
          <Row className={styles.copyright}>
            <p>
              Copyright © Red Robin Creative, {new Date().getFullYear()}. All
              rights reserved. View our{' '}
              <Link to="/privacy" onClick={() => window.scrollTo(0, 0)}>
                Privacy Policy
              </Link>
              .
            </p>
          </Row>
        </>
      )}
    </>
  );
};

export default Footer;
