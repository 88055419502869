import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gaPageView } from '../../analytics/ga';

const GoogleAnalytics = () => {
  const { pathname } = useLocation();
  useEffect(() => gaPageView(), [pathname]);

  return null;
};

export default GoogleAnalytics;
