import React, { useState, useEffect } from 'react';
import Carousel from 'react-slick';
import { getWebpEnabled } from '../../../../../../client-data/web-storage';

import mockup1 from '../../../../../../assets/images/homepage/RRC+-+JackjonPT+Website+-+iphone+8+.png';
import mockup1Webp from '../../../../../../assets/images/homepage/RRC-JackjonPT-website-mockup-iphone-w768.webp';
import mockup2 from '../../../../../../assets/images/homepage/RRC+-+JackjonPT+Website+-+iMac+Mockup+v2.2+no+shadow.png';
import mockup2Webp from '../../../../../../assets/images/homepage/RRC-JackjonPT-website-imac-mockup-w768.webp';

import styles from './AboveFold.module.css';

const AboveFoldCarousel = () => {
  const [supportsWebp, setWebp] = useState(getWebpEnabled() || true);
  const { Modernizr } = window;

  useEffect(
    () =>
      Modernizr.on(
        'webp',
        result => result !== supportsWebp && setWebp(result)
      ),
    [] // eslint-disable-line
  );

  const carouselConfig = {
    className: styles.carousel,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    speed: 1000,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3750,
    lazyLoad: true,
    pauseOnHover: false
  };

  const slides = [
    {
      src: mockup1,
      webp: mockup1Webp,
      alt:
        'Mockup of the JackjonPT website on desktop, designed by Red Robin Creative'
    },
    {
      src: mockup2,
      webp: mockup2Webp,
      alt:
        'Mockup of the JackjonPT website on desktop, designed by Red Robin Creative'
    }
  ];

  return (
    <Carousel {...carouselConfig}>
      {slides.map(({ webp, src, alt }) => (
        <figure key={alt}>
          <img src={supportsWebp ? webp : src} alt={alt} />
        </figure>
      ))}
    </Carousel>
  );
};

export default AboveFoldCarousel;
