import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
import { initGA } from './analytics/ga';

import './modernizr/webp';
import App from './Components/App/App';

import './index.css';
import './forms.css';
import './slick-carousel/slick/slick.css';
import './slick-carousel/slick/slick-theme.css';
import './iubenda.css';

TagManager.initialize({ gtmId: 'GTM-56W6962' });
initGA('UA-136311402-1');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  updateViaCache: 'none',
});
