import styled from 'styled-components';

export const MainRow = styled.section`
  @media only screen and (max-width: 480px) {
    width: unset;
  }
`;

export const Row = styled.div`
  @media only screen and (max-width: 480px) {
    width: unset;
  }
`;
