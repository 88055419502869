import React from 'react';
import { useHistory } from 'react-router-dom';
import { gaEvent } from '../../../../../../analytics/ga';

import { MainRow } from '../../../../../Styled/Rows';
import { Column } from '../../../../../Styled/Columns';
import { ColumnWrapper } from '../../../../../Styled/Wrappers';
import { Button } from '../../../../../Styled/Buttons';
import AboveFoldCarousel from './AboveFoldCarousel';

import { ReactComponent as Shape1 } from '../../../../../../assets/images/homepage/RRC-Shape2-BG.svg';
import styles from './AboveFold.module.css';

const AboveFold = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('./custom-website-quote-generator');
    gaEvent('engagement', 'online_quote_button_clicked', 'above_fold_section');
  };

  return (
    <MainRow className={styles.aboveFold}>
      <ColumnWrapper styledWidth="100%" className={styles.colWrapper}>
        <Column className={styles.left}>
          <h1>Get More Customers</h1>
          <h3 className={styles.tagline}>With a beautiful new website</h3>
          <Button onClick={handleClick} className={styles.cta}>
            Get an Online Quote
          </Button>
        </Column>
        <Column className={styles.right}>
          <AboveFoldCarousel />
        </Column>
      </ColumnWrapper>
      <Shape1 className={styles.shape1} />
    </MainRow>
  );
};

export default AboveFold;
