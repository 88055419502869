import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/images/global/red-robin-creative-logo.svg';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import throttle from 'lodash.throttle';

import DesktopNavDrawer from '../Header/Navigation/DesktopNavDrawer/DesktopNavDrawer';
import Hamburger from '../Controls/Hamburger/Hamburger';
import { Button } from '../Styled/Buttons';
import { OutlineIconButton } from '../Styled/Buttons';

const StyledHeader = styled.header`
  transition: all 0.2s ease-in-out;
  height: ${({ isScrolled, isA11yMode, theme: { heights } }) =>
    isScrolled || isA11yMode
      ? heights.stickyHeaderDesktopSmall
      : heights.stickyHeaderDesktop};
  background-color: ${({ isA11yMode, isScrolled, pathname }) => {
    if (isA11yMode) return 'rgba(255, 255, 255, 0.85)';

    const isNotHomepage = pathname !== '/';

    if (isScrolled || isNotHomepage) return '#2c2c2c';
    else return 'transparent';
  }};
  border-bottom: ${({ isA11yMode }) =>
    isA11yMode ? '1px solid #000000' : 'none'};
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10060;

  @media only screen and (max-width: 480px) {
    height: 80px;

    ${({ isScrolled, isA11yMode, theme: { heights } }) => {
      if (isScrolled || isA11yMode) {
        return `height: ${heights.stickyHeaderMobileSmall};`;
      } else return '';
    }}
  }
`;

const StyledContentContainer = styled.div`
  transition: inherit;
  padding: 0 1.5rem;
  height: ${({ isScrolled, isA11yMode, theme: { heights } }) =>
    isScrolled || isA11yMode
      ? heights.stickyHeaderDesktopSmall
      : heights.stickyHeaderDesktop};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;

  ${({ isScrolled, isA11yMode }) =>
    isScrolled && !isA11yMode
      ? 'box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.33);'
      : ''};

  a {
    text-decoration: inherit;
  }

  *:not(.desktop-nav-drawer) {
    transition: inherit;
  }

  @media only screen and (max-width: 480px) {
    height: ${({ isScrolled, isA11yMode, theme: { heights } }) =>
      isScrolled || isA11yMode ? heights.stickyHeaderMobileSmall : '80px'};

    ${({ isScrolled, isA11yMode }) =>
      isScrolled || isA11yMode ? 'padding: 0 1.25rem;' : ''};
  }
`;

const StyledHeaderLogoContainer = styled.div`
  display: flex;

  svg {
    cursor: pointer;
    margin-top: 2px;
    height: ${({ isScrolled, isA11yMode }) =>
      isScrolled || isA11yMode ? '42.5px' : '52.5px'};

    path {
      fill: ${({ isA11yMode, isScrolled }) => {
        if (isA11yMode) return '#000000';
        return isScrolled ? '#ef3444' : '#ffffff';
      }};
    }
  }

  @media only screen and (max-width: 1024px) and (min-width: 481px) {
    svg {
      margin-left: ${({ isScrolled, isA11yMode }) =>
        isScrolled || isA11yMode ? '3px' : '10px'};
    }
  }

  @media only screen and (max-width: 480px) {
    svg {
      height: ${({ isScrolled, isA11yMode }) =>
        isScrolled || isA11yMode ? '37.5px' : '42.5px'};
    }
  }
`;

const StyledHeaderControls = styled.div`
  padding: ${({ isScrolled, isA11yMode }) =>
    isScrolled || isA11yMode ? '0 45px' : '0 57px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 11rem;
  ${({ isScrolled, isA11yMode }) =>
    isScrolled || isA11yMode ? 'transform: scale(0.9);' : ''};

  @media only screen and (max-width: 1024px) and (min-width: 481px) {
    padding: ${({ isScrolled, isA11yMode }) =>
      isScrolled || isA11yMode ? '0 35px' : '0 50px'};
  }

  @media only screen and (max-width: 480px) {
    justify-content: flex-end;
    width: unset;
    padding: ${({ isScrolled, isA11yMode }) =>
      isScrolled || isA11yMode ? '0 1.75rem' : '0 2.625rem'};
  }
`;

const StyledLoginButton = styled(OutlineIconButton)`
  transition: all 0.3s, opacity 0.5s ease-out;

  ${({ isA11yMode, isOpen }) => {
    if (isA11yMode || isOpen) {
      return `
      transition: inherit;
      pointer-events: none;
      opacity: 0;`;
    } else return '';
  }}

  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const StyledHamburgerButton = styled(Hamburger)`
  z-index: 1;

  > span {
    ${({ isA11yMode, isOpen }) =>
      isA11yMode && !isOpen ? 'border-top: 2px solid #000000;' : ''};
  }
`;

const StyledScrollDownButton = styled(Button)`
  transition: all 0.3s ease-in-out;
  position: fixed;
  z-index: 20;
  bottom: 0.33rem;
  left: calc(50vw - 26px);
  font-size: 2.75rem;
  background-color: white;
  padding: 0.25rem;
  animation: pulse 0.75s ease-in-out infinite alternate-reverse;

  ${({ isScrolled }) => {
    if (isScrolled) {
      return `
      height: unset;
      opacity: 0;
      pointer-events: none;`;
    } else return '';
  }}

  path {
    fill: #da2859;
  }

  :hover {
    background-color: #28cead;
  }

  :hover path {
    fill: white;
  }

  @keyframes pulse {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.125);
    }
  }

  @media only screen and (max-width: 480px) {
    bottom: 0.375rem;
    left: calc(50vw - 20px);
    font-size: 2rem;

    path {
      fill: #ca2a4e;
    }
  }

  @media only screen and (max-height: 567px) {
    display: none;
  }
`;

const Header = ({ setPathname, isScrolled, setIsScrolled, isA11yMode }) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const messageHandler = ({
    origin,
    data: { isScrollMessage, isScrolled },
  }) => {
    const regex = /^https:\/\/(blog|editor)\.redrobincreative\.com$/;
    const messageNotFromBlogOrEditorSubDomains = !regex.test(origin);

    if (messageNotFromBlogOrEditorSubDomains) return;
    if (isScrollMessage) setIsScrolled(isScrolled);
  };

  const scrollHandler = throttle(
    () => {
      setIsScrolled(document.documentElement.scrollTop > 3);
    },
    30,
    { leading: true, trailing: true }
  );

  useEffect(() => {
    setPathname(pathname);
    setIsScrolled(false);
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleMessage = (e) => messageHandler(e);
    window.addEventListener('message', handleMessage, false);

    const handleScroll = () => scrollHandler();
    window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.removeEventListener('message', handleMessage, false);
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledHeader
        isA11yMode={isA11yMode}
        isScrolled={isScrolled}
        pathname={pathname}
      >
        <DesktopNavDrawer
          className="desktop-nav-drawer"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <StyledContentContainer isA11yMode={isA11yMode} isScrolled={isScrolled}>
          <HashLink to="/#home">
            <StyledHeaderLogoContainer
              isA11yMode={isA11yMode}
              isScrolled={isScrolled}
            >
              <Logo title="The Red Robin Creative Logo" />
            </StyledHeaderLogoContainer>
          </HashLink>
          <StyledHeaderControls isScrolled={isScrolled}>
            <StyledLoginButton
              isA11yMode={isA11yMode}
              onClick={() =>
                window.location.assign(
                  'https://editor.redrobincreative.com/login'
                )
              }
              isOpen={isOpen}
            >
              <Icon icon="sign-in-alt"></Icon>
              Login
            </StyledLoginButton>
            <StyledHamburgerButton
              isA11yMode={isA11yMode}
              ariaLabel="Toggle Main Menu"
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </StyledHeaderControls>
        </StyledContentContainer>
      </StyledHeader>
      {pathname === '/' && (
        <StyledScrollDownButton
          onClick={() => window.location.assign('./#features')}
          isScrolled={isScrolled}
          ariaLabel="Scroll down"
        >
          <Icon icon="arrow-alt-circle-down" />
        </StyledScrollDownButton>
      )}
    </>
  );
};

export default Header;
