import React, { createRef, useEffect } from 'react';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import {
  enableScroll,
  disableScroll,
} from '../../../Utils/EnableDisableScroll';

import { Row } from '../../../Styled/Rows';
import { ColumnWrapper } from '../../../Styled/Wrappers';
import { Column } from '../../../Styled/Columns';
import { Button } from '../../../Styled/Buttons';
import NewsletterSub from '../../../Forms/NewsletterSub/NewsletterSub';

import styles from './DesktopNavDrawer.module.css';

const overlay = createRef();

const DesktopNavDrawer = ({ className, isOpen, setIsOpen }) => {
  const { pathname } = useLocation();
  const handlers = useSwipeable({
    onSwipedUp: (_) => setIsOpen(false),
  });

  const handleClick = () => setIsOpen(false);

  const navigateToBlogAndRefreshPage = () => {
    if (pathname.includes('blog')) {
      window.location.pathname = 'blog';
    }
  };

  useEffect(() => {
    const list = overlay?.current?.classList;
    if (list) {
      if (isOpen) {
        disableScroll();
        list.remove(styles.fadeOut);
        list.add(styles.fadeIn);
      } else {
        enableScroll();
        list.remove(styles.fadeIn);
        list.add(styles.fadeOut);
      }
    }
  }, [isOpen]);

  return (
    <>
      <div ref={overlay} className={styles.overlay} onClick={handleClick} />
      <div
        className={`${className} ${styles.drawer} ${isOpen && styles.open}`}
        {...handlers}
      >
        <Row className={styles.row}>
          <ColumnWrapper className={styles.colWrapper} styledWidth="960px">
            <Column className={styles.left}>
              <nav onClick={handleClick}>
                <ul className={styles.navList}>
                  <li>
                    <Link to="/portfolio">Portfolio</Link>
                  </li>
                  <li>
                    <Link to="/#features">Features</Link>
                  </li>
                  <li>
                    <Link to="/#pricing">Pricing</Link>
                  </li>
                  <li onClick={navigateToBlogAndRefreshPage}>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/#lets-chat">Contact us</Link>
                  </li>
                  <li>
                    <a href="https://editor.redrobincreative.com/login">
                      <Icon icon="sign-in-alt" />
                      Login
                    </a>
                  </li>
                </ul>
              </nav>
            </Column>
            <Column className={styles.right}>
              <div className={styles.socialLink}>
                <h3>Follow us</h3>
                <div className={styles.linkWrapper}>
                  <Button
                    ariaLabel="Email us"
                    onClick={() =>
                      window.open(
                        'mailto:hello@redrobincreative.com?subject=I visited your site at Red Robin Creative...',
                        '_blank'
                      )
                    }
                  >
                    <Icon icon={['far', 'envelope']} />
                  </Button>
                  <Button
                    ariaLabel="Visit us on Twitter"
                    onClick={() =>
                      window.open(
                        'https://twitter.com/redrobincreates',
                        '_blank'
                      )
                    }
                  >
                    <Icon icon={['fab', 'twitter']} />
                  </Button>
                </div>
              </div>
              <div>
                <NewsletterSub className={styles.subForm} />
              </div>
              <p
                className={styles.recentPosts}
                onClick={() => {
                  handleClick();
                  navigateToBlogAndRefreshPage();
                }}
              >
                <Link to="/blog">
                  <Icon icon="long-arrow-alt-right" />
                  View our recent blog posts
                </Link>
              </p>
            </Column>
          </ColumnWrapper>
        </Row>
      </div>
    </>
  );
};

export default DesktopNavDrawer;
