import ReactGA from 'react-ga';

export const initGA = id => {
  ReactGA.initialize(id);
};

export const gaPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const gaEvent = (category, eventName, label) => {
  ReactGA.event({
    category: category,
    action: eventName,
    label: label
  });
};
