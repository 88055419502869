import React from 'react';
import styled from 'styled-components';

const StyledSpinner = styled.div`
  display: inline-block;
  border-color: currentColor;
  border-style: solid;
  border-radius: 99999px;
  border-width: 5px;
  border-bottom-color: rgba(225, 231, 239, 0.5);
  border-left-color: rgba(225, 231, 239, 0.5);
  animation: spin-animation 0.65s linear infinite;
  height: 70px;
  width: 70px;
  color: #28cead;

  > span {
    border: 0px;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0px;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerV2 = ({
  height = '70px',
  width = '70px',
  thickness = '5px',
  speed = '0.65s',
  emptyColor = 'rgba(225, 231, 239, 0.5)',
  color = '#28cead',
}) => (
  <StyledSpinner
    height={height}
    width={width}
    thickness={thickness}
    speed={speed}
    emptyColor={emptyColor}
    color={color}
  >
    <span />
  </StyledSpinner>
);

export default SpinnerV2;
