export const setDocBodyBgColor = () => {
  const { pathname } = window.location;
  let className;

  switch (true) {
    case pathname === '/':
      className = 'home-bg';
      break;
    case pathname.includes('/blog'):
      className = 'blog-bg';
      break;
    default:
      className = 'default-bg';
      break;
  }

  document.body.className = className;
};
