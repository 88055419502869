const SEEN_PRIVACY_NOTIFICATION_BAR = 'SEEN_PRIVACY_NOTIFICATION_BAR';
const WEBP_ENABLED = 'WEBP_ENABLED';

const setItem = (key, value, storageType = 'local') => {
  const webStorage =
    storageType === 'local'
      ? localStorage
      : storageType === 'session' && sessionStorage;
  if (webStorage) webStorage.setItem(key, JSON.stringify(value));
};

const getItem = (key, storageType = 'local') => {
  const webStorage =
    storageType === 'local'
      ? localStorage
      : storageType === 'session' && sessionStorage;
  return webStorage && JSON.parse(webStorage.getItem(key));
};

export const setIsPrivacyNotifBarSeen = (value = true) => {
  setItem(SEEN_PRIVACY_NOTIFICATION_BAR, value);
};
export const setWebpEnabled = (value) => {
  setItem(WEBP_ENABLED, value);
};

export const getIsPrivacyNotifBarSeen = () =>
  getItem(SEEN_PRIVACY_NOTIFICATION_BAR);

export const getWebpEnabled = () => getItem(WEBP_ENABLED);
