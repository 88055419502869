import React, { useEffect } from 'react';
import styled from 'styled-components';
import SpinnerV2 from './SpinnerV2';
import { isMobile } from 'react-device-detect';
import { enableScroll, disableScroll } from '../Utils/EnableDisableScroll';

const StyledPlaceholder = styled.div`
  height: ${({ theme: { heights } }) =>
    `calc(100vh - ${heights.stickyHeaderDesktop})`};
  width: 100vw;

  @media only screen and (max-width: 480px) {
    height: ${({ theme: { heights } }) =>
      `calc(100vh - ${heights.stickyHeaderMobile})`};
  }
`;

const StyledContainer = styled.div`
  background-color: rgba(225, 231, 239, 0.2);
  height: ${({ offsetForHeader, theme: { heights } }) => {
    if (offsetForHeader && isMobile)
      return `calc(100vh - ${heights.stickyHeaderMobile})`;
    if (offsetForHeader) return `calc(100vh - ${heights.stickyHeaderDesktop})`;
    return '100vh';
  }};
  width: 100vw;
  position: fixed;
  top: ${({ offsetForHeader, theme: { heights } }) => {
    if (offsetForHeader && isMobile) return heights.stickyHeaderMobile;
    if (offsetForHeader) return heights.stickyHeaderDesktop;
    return '0';
  }};
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h2 {
    font-size: 2rem;
    padding: 0 3rem;
    margin-top: 1.75rem;
    color: #000;
    text-align: center;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
      1px 1px 0 #fff;
    letter-spacing: 0.05rem;
  }
`;

const FullPageLoader = ({
  displayAboveContent = false,
  withText = false,
  offsetForHeader = true,
  noScroll = false,
}) => {
  useEffect(() => {
    if (noScroll) disableScroll();

    return () => {
      if (noScroll) enableScroll();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!displayAboveContent && <StyledPlaceholder />}
      <StyledContainer offsetForHeader={offsetForHeader}>
        <SpinnerV2 />
        {withText && <h2>LOADING AMAZING THINGS</h2>}
      </StyledContainer>
    </>
  );
};

export default FullPageLoader;
