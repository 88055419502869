import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';

import AboveFold from './Rows/AboveFold/AboveFold';
import FullPageLoader from '../../../Shared/FullPageLoader';

import styles from './Home.module.css';

const OurServices = lazy(() => import('./Rows/OurServices/OurServices'));
const Personalisation = lazy(() =>
  import('./Rows/Personalisation/Personalisation')
);
const Pricing = lazy(() => import('./Rows/Pricing/Pricing'));
const LetsChat = lazy(() => import('./Rows/LetsChat/LetsChat'));

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Red Robin Creative - Web Design and Digital Marketing.</title>
        <meta
          name="description"
          content="UK based Web Design &amp; Digital Marketing Agency focused at helping SMBs manage their online presence. We blog too! - Get free advice from The Digital Quill."
        />
        <link rel="canonical" href="https://www.redrobincreative.com" />
      </Helmet>
      <hr id="home" className={styles.homeMarker} />
      <AboveFold />
      <hr id="features" className={styles.featuresMarker} />
      <Suspense
        fallback={
          <FullPageLoader
            offsetForHeader={false}
            displayAboveContent={true}
            noScroll={true}
          />
        }
      >
        <OurServices />
        <Personalisation />
        <hr id="pricing" className={styles.pricingMarker} />
        <Pricing />
        <hr id="lets-chat" className={styles.contactMarker} />
        <LetsChat />
      </Suspense>
    </>
  );
};

export default Home;
