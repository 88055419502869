import React, { forwardRef } from 'react';
import styled from 'styled-components';

export const StyledButton = styled.button`
  cursor: pointer;
  margin: 0.625rem 0;
  outline: none;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 50px;
  background-color: black;
  color: white;

  &:hover {
    transform: scale(1.025);
    background-color: #191919;
  }

  @media only screen and (max-width: 320px) {
    font-size: 0.875rem;
  }
`;

export const Button = forwardRef(
  ({ ariaLabel, children, ...restOfProps }, ref) => (
    <StyledButton ref={ref} aria-label={ariaLabel} {...restOfProps}>
      {children}
    </StyledButton>
  )
);

export const IconButton = styled(Button)`
  padding: 0.75rem 2rem 0.75rem 1.95rem;

  & svg {
    pointer-events: none;
    margin-right: 0.5rem;
    font-size: 1.25rem;
    align-self: center;
  }

  &:hover path {
    fill: #0ccead;
  }
`;

export const OutlineIconButton = styled(Button)`
  background-color: transparent;
  padding: 0.75rem 1.1rem;
  border: 2px solid white;
  font-weight: bold;

  & svg {
    pointer-events: none;
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:hover path {
    fill: #0ccead;
  }
`;
