import React from 'react';
import { Helmet } from 'react-helmet';

const DefaultHelmet = () => {
  return (
    <Helmet>
      <title>Red Robin Creative - Web Design and Digital Marketing.</title>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="description"
        content="UK based Web Design &amp; Digital Marketing Agency focused at helping SMBs manage their online presence. We blog too! - Get free advice from The Digital Quill."
      />
      <link rel="canonical" href="https://www.redrobincreative.com" />

      <meta name="apple-mobile-web-app-title" content="Red Robin Creative" />
      <meta name="application-name" content="Red Robin Creative" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta
        name="msapplication-config"
        content="%PUBLIC_URL%/favicons/browserconfig.xml"
      />
      <meta name="theme-color" content="#ffffff" />

      <meta
        property="og:title"
        content="Red Robin Creative - Web Design and Digital Marketing."
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="UK based Web Design &amp; Digital Marketing Agency focused at helping SMBs manage their online presence. We blog too! - Get free advice from The Digital Quill."
      />
      <meta
        property="og:image"
        content="https://cdn.redrb.in/file/rrc/images/global/red-robin-creative-open-graph-image.png"
      />
      <meta property="og:site_name" content="Red Robin Creative" />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:url" content="https://www.redrobincreative.com" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@redrobincreates" />
      <meta
        name="twitter:title"
        content="Red Robin Creative - Web Design and Digital Marketing."
      />
      <meta
        name="twitter:description"
        content="UK based Web Design &amp; Digital Marketing Agency focused at helping SMBs manage their online presence. We blog too! - Get free advice from The Digital Quill."
      />
      <meta
        name="twitter:image"
        content="https://cdn.redrb.in/file/rrc/images/global/red-robin-creative-open-graph-image.png"
      />
      <meta name="twitter:creator" content="@redrobincreates" />
      <meta name="twitter:domain" content="redrobincreative.com" />
    </Helmet>
  );
};

export default DefaultHelmet;
