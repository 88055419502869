import React, { useState } from 'react';
import jsonp from 'jsonp';
import * as sanitize from 'xss';

import { Button } from '../../Styled/Buttons';

import styles from './NewsletterSub.module.css';

const NewsletterSub = ({ className }) => {
  const sanitizeOptions = { whiteList: sanitize.getDefaultWhiteList() };
  // remove target attribute from anchor tag in default whitelist:
  sanitizeOptions.whiteList.a = ['href', 'title'];
  // mailchimp form variables:
  const u = '479ddff43463e4185f660d8ce';
  const id = '96dd5ef02c';
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [consent, setConsent] = useState(undefined);

  const handleSubmit = e => {
    e.preventDefault();
    e.target.querySelector('#consent').checked = false;
    setConsent(undefined);
    setStatus('sending');
    jsonp(
      `https://redrobincreative.us20.list-manage.com/subscribe/post-json?
      u=${u}&
      id=${id}&
      MERGE0=${email}&
      MERGE1=${firstName}&
      MERGE2=${lastName}
      ${consent ? '&group[5706][1]' : ''}`,
      {
        param: 'c'
      },
      (err, { msg, result }) => {
        if (err) {
          setStatus('error');
          setMessage('Ooops! Something went wrong. Please try again later.');
        } else if (result !== 'success') {
          setStatus('error');
          setMessage(msg);
        } else {
          setStatus('success');
          setMessage(msg);
          setFirstName('');
          setLastName('');
          setEmail('');
        }
      }
    );
  };

  return (
    <>
      {status === 'sending' && <h4 className={styles.message}>Sending...</h4>}
      {status === 'success' && (
        <h4 className={styles.message}>Success! {message}</h4>
      )}
      {status === 'error' && (
        <h4
          className={styles.error}
          dangerouslySetInnerHTML={{
            __html: sanitize(message, sanitizeOptions)
          }}
        />
      )}
      <form className={className} onSubmit={e => handleSubmit(e)}>
        <h3>Join our newsletter</h3>
        <p>Get the latest news delivered to your inbox</p>
        <label>
          <input
            aria-label="First name"
            placeholder="First name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            required
          />
        </label>
        <label>
          <input
            aria-label="Last name"
            placeholder="Last name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            required
          />
        </label>
        <label>
          <input
            type="email"
            aria-label="Your email address"
            placeholder="Your email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </label>
        <label className={styles.check}>
          <input
            id="consent"
            type="checkbox"
            value={consent}
            onChange={e =>
              setConsent(
                e.target.checked
                  ? 'I agree to recieving marketing emails.'
                  : undefined
              )
            }
            required
          />
          <span>I agree to recieving marketing emails.</span>
        </label>
        <Button className={styles.submit}>Subscribe</Button>
      </form>
    </>
  );
};

export default NewsletterSub;
