const env = process.env.NODE_ENV;

const defaultConfig = {
  blog: {
    iframe: {
      srcBaseUrl: 'https://editor.redrobincreative.com/site/e2feb62c',
      srcQueryString: '?preview=true',
    },
    postMessageOrigin: 'https://editor.redrobincreative.com',
    ampEnabled: {
      '/8-top-tips-for-managing-your-online-reputation': true,
      '/social-media-management-is-a-must-for-every-small-business': true,
      '/put-your-effort-in-selling-not-in-building-your-website': true,
      '/all-big-businesses-were-small-at-the-beginning': true,
      '/here-are-the-top-social-media-strategies-for-your-business': true,
      '/what-is-google-my-business-and-why-do-i-need-it': true,
      '/7-stellar-statistics-for-small-business-websites': true,
      '/four-ways-a-website-can-help-a-small-business-thrive': true,
    },
  },
  chatbot: {
    disallowedPathnames: ['/support', '/sign', '/blog'],
  },
  legal: {
    privacyPolicyApiEndpoint:
      'https://www.iubenda.com/api/privacy-policy/61556378/only-legal',
    cookiePolicyApiEndpoint:
      'https://www.iubenda.com/api/privacy-policy/61556378/cookie-policy',
    termsAndConditionsApiEndpoint:
      'https://www.iubenda.com/api/terms-and-conditions/61556378',
  },
};

const development = {
  ...defaultConfig,
};

const test = {
  ...defaultConfig,
};

const production = {
  ...defaultConfig,
  blog: {
    ...defaultConfig.blog,
    iframe: {
      srcBaseUrl: 'https://blog.redrobincreative.com',
      srcQueryString: '',
    },
    postMessageOrigin: 'https://blog.redrobincreative.com',
  },
};

const exportConfig = {
  development,
  test,
  production,
};

export default exportConfig[env];
