// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

const preventDefault = (e) => {
  e.preventDefault();
};

const preventDefaultForScrollKeys = (e) => {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
};

const getWheelValues = () => {
  // modern Chrome requires { passive: false } when adding event
  let supportsPassive = false;

  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        // eslint-disable-next-line getter-return
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {}

  return {
    wheelOpt: supportsPassive ? { passive: false } : false,
    wheelEvent:
      'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel',
  };
};

export const enableScroll = () => {
  const { wheelOpt, wheelEvent } = getWheelValues();

  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
};

export const disableScroll = () => {
  const { wheelOpt, wheelEvent } = getWheelValues();

  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
};
